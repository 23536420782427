<script lang="ts" setup>
import type { NotificationMessagePayload } from "@netgame/composables/src/useNotificationCenter";

import type { InitDataResponseData } from "@/types";

const { t } = useT();

const props = defineProps<{
	message: NonNullable<NonNullable<InitDataResponseData>["notificationCenter"]>["messages"][number];
}>();
const emit = defineEmits<{
	(event: "clickOnMessage", payload: NotificationMessagePayload): void;
}>();
const { durationLeft, duration } = useCountdown({ timestamp: props.message.dateEnd });

const isShowTimer = computed(() => {
	if (props.message.type !== "offer") {
		return false;
	}
	const isStartLessNow = new Date(props.message.dateBegin).getTime() <= new Date().getTime();
	const isEndMoreNow = new Date(props.message.dateEnd).getTime() > new Date().getTime();
	return isStartLessNow && isEndMoreNow;
});

const handleClickMessage = () => {
	dispatchGAEvent({
		event: "click_button",
		location: props.message.isReaded ? "earlier" : "new",
		button_name: "notification",
		type: props.message.systemAlias
	});

	emit("clickOnMessage", { id: props.message.ncMessageId, needSendStatusToServer: true, shouldOpenLink: true });
};
</script>

<template>
	<div v-if="message" class="message" @click="handleClickMessage">
		<img :src="message.image?.replace('http:', 'https:')" :alt="message.title" width="36" height="36" />
		<div class="message-content">
			<AText class="text-cannes" variant="topeka" as="div">{{ message.title }}</AText>
			<AText class="text-caracas" variant="topeka" as="div">{{ message.message }}</AText>
		</div>
		<div :class="{ action: isShowTimer && duration }">
			<AButton v-if="message.link" size="xs">
				<AText variant="topeka">{{ message.buttonLabel || t("More") }}</AText>
			</AButton>
			<AText v-if="isShowTimer && duration" class="text-cannes" variant="ternopil">
				<i18n-t keypath="{key} left">
					<template #key>
						{{ durationLeft }}
					</template>
				</i18n-t>
			</AText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.message {
	margin-top: gutter(2);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	transition: opacity 500ms ease-in-out;
	img {
		margin-right: gutter(1);
	}
	button {
		margin-left: gutter(1);
	}
	.action {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 100px;
	}
	&-content {
		flex: 1;
	}
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
